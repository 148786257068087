body {
  background-color: #f7f7fa;
}

body.ar {
  direction: rtl;
  text-align: initial;
  unicode-bidi: embed;
  font-family: 'al-jazeera-arabicbold' !important;
}

body.en {
  font-family: 'Roboto' !important;
}

.ar td,
.ar .recommended-box,
.ar .p,
.ar button,
.ar .navbar,
.ar .table-headings,
.ar .card,
.ar div {
  font-family: 'al-jazeera-arabicbold' !important;
}

.en td,
.en .recommended-box,
.en .p,
.en button,
.en .navbar,
.en .table-headings,
.en .card,
.en div {
  font-family: Roboto !important;
}

.bg-new-primary {
  background-color: #222c4a;
}

.loginBtn .btn-primary {
  background-image: linear-gradient(to right, #EE855D, #DA512D) !important;
  border: none;
}

.loginBtn .btn-primary:disabled {
  background-color: linear-gradient(to right, #EE855D, #DA512D) !important;
  border: none;
}

#otpView {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  outline: none;
}

body .topnav {
  background-color: #222b4a !important;
}

.heading-text {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 24px/22px Roboto;
  letter-spacing: 0px;
  color: #222B4A;
  opacity: 1;
}

.data-text {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 29px;
  // font: normal normal 500 16px/22px Roboto;
  letter-spacing: 0px;
  color: #222B4A;
  opacity: 1;
  margin-right: 7px;

}

.light-data-text {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 24px;
  // font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0.15px;
  color: #222B4A;
  opacity: 1;
}

.dark-data-text {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 24px;
  // font: normal normal bold 16px/24px Roboto;
  letter-spacing: 0.15px;
  color: #222B4A;
  opacity: 1;
}

.heading-orange-text {
  font-size: 20px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 24px;
  // font: normal normal bold 20px/24px Roboto;
  letter-spacing: 0.19px;
  color: #DA512D;
  opacity: 1;
}

.mid-white-text {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20px;
  // font: normal normal 500 16px/20px Roboto;
  letter-spacing: 0.19px;
  color: white;
  opacity: 1;
}

.light-white-text {
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
  // font: normal normal 400 14px/20px Roboto;
  letter-spacing: 0.19px;
  color: white;
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.lh-1 {
  line-height: 1;
}

textarea:disabled {
  background-color: #eff2f7 !important;
}

.status-badge {
  border: 1px solid transparent;
  padding: 0.27rem 0.45rem;
  font-size: 0.8125rem;
  line-height: 1.5;
}

.description-tooltip {
  background-color: #eff2f7;
  color: #495057 !important;
  margin: 2px 10px 30px !important;
  letter-spacing: 0.5px;
  font-size: 11px;
  white-space: pre-line;
}

.mat-tooltip {
  font-size: 14px !important;
}

.tutorial {
  background-color: #333333;
  z-index: 100000;
  min-width: 100%;
  text-align: center;
}

.tutorial img {
  cursor: pointer;
}

@media screen and (min-width: 786px) {
  .modal-confirmation {
    .modal-dialog {
      max-width: 725px;
    }
  }
}

.mat-form-field-flex {
  .mat-form-field-outline {

    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-color: #222b4a;
      border-width: 1px solid !important;
    }

    &.mat-form-field-outline-thick {

      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end {
        border-color: rgba(85, 110, 230, 0.25);
        border-width: 1px solid !important;
      }
    }
  }
}

.tpl-details {

  .mat-form-field-outline-thick,
  .mat-form-field-outline {
    background-color: #dfe7ed;
    border-radius: 5px;
  }
}

.add-quote {

  .mat-form-field-outline-thick,
  .mat-form-field-outline {
    background-color: #fff;
    border-radius: 5px;
  }
}

.ngx-select .mat-select-search-input {
  border: none;
}

.ngx-select .mat-select-search-input:focus-visible {
  outline: none;
}

.ngx-select .cdk-overlay-pane,
.tpl-payment .cdk-overlay-pane {
  transform: translateX(-14px) translateY(0px) !important;
}

.loader-backdrop {
  opacity: 0.3 !important;
  background: #fff;
  pointer-events: none;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 15px;
  transform: translateY(-1.71em) scale(0.75);
}

.lightboxOverlay {
  width: 100% !important;
  height: 100vh !important;
  overflow: auto;
}

// My Vehicles CSS
.table .thead-light th {
  color: #222b4a !important;
}

.add-car-btn {
  background-color: #da512d;
  border-color: #da512d;
}

.add-car-btn:hover {
  background-color: #da512d;
  border-color: #da512d;
}

// Add Vehicle CSS
.add-vehicle-card-body {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.card-title {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 24px/22px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
}

.mat-form-field-flex .mat-form-field-outline.mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-flex .mat-form-field-outline.mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-flex .mat-form-field-outline.mat-form-field-outline-thick .mat-form-field-outline-end {
  border-color: #da512d;
}

.mat-form-field-label {
  letter-spacing: 0.15px;
  color: #00000099;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #da512d;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #da512d;
}

.get-quote-btn,
.get-quote-btn:disabled,
.get-quote-btn:hover {
  background-color: #222b4a;
  border-color: #222b4a;
}

.upload-image-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.choose-file-section,
.QR-section {
  border: 3px dashed #98bcd5;
  border-radius: 10px;
  opacity: 1;
}

.quoteSummary .choose-file-section {
  border: none;
}

.upload-images,
.uploaded-count {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 24px/22px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.images-fields label,
::marker {
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 22px;
  // font: normal normal 500 18px/22px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.or-label {
  font-size: 36px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 36px/22px Roboto;
  letter-spacing: 0px;
  color: #da512d;
  opacity: 1;
}

::marker {
  text-indent: 3px;
}

// input[type="file"] {
//   position: absolute;
//   width: 1px;
//   height: 1px;
//   padding: 0;
//   margin: -1px;
//   overflow: hidden;
//   clip: rect(0, 0, 0, 0);
//   border: 0;
// }
.custom-input,
.custom-input-upload {
  color: transparent;
  width: 106px;
}

.custom-input::-webkit-file-upload-button,
.custom-input-upload::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-input::before {
  content: 'Choose File';
  display: inline-block;
  border-radius: 2px;
  padding: 6px 12px;
  outline: none;
  white-space: nowrap;
  //-webkit-user-select: none;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 22px;
  // font: normal normal normal 16px/22px Roboto !important;
  color: #222b4a !important;
}

.ar .custom-input::before {
  content: 'اختر ملف';
}

.custom-input-upload::before {
  content: 'Uploaded';
  display: inline-block;
  border-radius: 2px;
  padding: 6px 19px;
  outline: none;
  white-space: nowrap;
  //-webkit-user-select: none;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 22px;
  // font: normal normal normal 16px/22px Roboto !important;
  color: #222b4a !important;
}

.ar .custom-input-upload::before{
  content: 'تم التحميل';
}
.custom-input:hover::before,
.custom-input-upload:hover::before {
  border-color: black;
}

.custom-input:active::before,
.custom-input-upload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.custom-file-upload {
  margin-right: 5px;
  background: #98bcd5 0% 0% no-repeat padding-box;
}
.ar .custom-file-upload{
  margin-left: 5px;
}

.download-text {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 24px/22px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.users-text {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 29px;
  // font: normal normal 500 24px/29px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
  text-align: center;
}

.back-btn {
  border: 1px solid #222b4a;
  border-radius: 5px;
  opacity: 1;
  background: white;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.back-btn button {
  border: 1px solid #222b4a;
  border-radius: 5px;
  opacity: 1;
  background: white;
  width: 210px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 8px;
  // font: normal normal bold 16px/8px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.back-btn button:hover {
  background: #222c4a;
  color: white;
}

.image-uploaded span {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 24px/22px Roboto;
  letter-spacing: 0px;
  color: #222b4a;
  opacity: 1;
}

.image-uploaded button {
  background: #222c4a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: white;
  width: 250px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 8px;
  // font: normal normal bold 16px/8px Roboto;
  letter-spacing: 0px;
  opacity: 1;
}

.image-uploaded button:hover {
  color: white;
}

.choose-file-section,
.QR-section,
.OR-part {
  flex: 0% !important;
}

.choose-file-section,
.QR-section {
  max-width: 45% !important;
}

.OR-part {
  max-width: 10% !important;
}


// Stepper CSS

.comprehensive-stepper {
  counter-reset: step;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: end;
}

.comprehensive-stepper li {
  list-style-type: none;
  position: relative;
  z-index: 1;
  font-size: 12px;
  color: #98BCD5;
  display: inline-block;
  text-align: center;
}

.comprehensive-stepper li.active {
  color: #00b877;
}

.comprehensive-stepper li span.bar-circle {
  content: counter(step);
  counter-increment: step;
  width: 45px;
  height: 45px;
  line-height: 40px;
  // border: 1px solid #999;
  /* display: block; */
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #98BCD5;
  font-weight: bold;
  font-size: 18px;
  right: 0;
  padding: 8px 15px;
  box-shadow: 0px 3px 6px #00000029;
}

.ar .comprehensive-stepper li span.disable-bar {
  left: -135px !important;
}

.ar .recommended-box {
  right: -5px;
  width: 104% !important;
}

.comprehensive-stepper li span.disable-bar,
.comprehensive-stepper li span.active {
  position: relative;
  width: 230px;
  // background-color: #ccc;
  height: 2px;
  top: -60px;
  left: 135px;
  z-index: -2;
  display: inline-flex;
  margin-right: 8px;
  margin-left: 8px;
  border: 2px dashed #98BCD6;
  border-bottom: 0px;
}

.active-tab span.bar-circle {
  color: white !important;
}

.comprehensive-stepper li span.active {
  display: none;
}

.comprehensive-stepper li.active span.active {
  background-color: #222c4a;
  margin-left: -68px;
  display: inline-block;
}

.comprehensive-stepper li.active-tab span.text {
  display: block;
  color: #222B4a;
  font-size: 14px;
  font-weight: 500;
}

.comprehensive-stepper li span.text {
  // margin-left: 8px;
  display: block;
  margin-top: 8px;
}

.comprehensive-stepper li span.active {
  width: 0;
}

.comprehensive-stepper li.active-tab:nth-child(1) span.bar-circle,
.comprehensive-stepper li.active:nth-child(1) span.bar-circle {
  animation-name: fillColorAnimation;
  // animation-duration: .5s;
  animation-fill-mode: forwards;
}

.comprehensive-stepper li.active-tab:nth-child(2) span.bar-circle,
.comprehensive-stepper li.active:nth-child(2) span.bar-circle {
  animation-name: fillColorAnimation;
  // animation-duration: .5s;
  animation-fill-mode: forwards;
  // animation-delay: 1s;
}

.comprehensive-stepper li.active-tab:nth-child(3) span.bar-circle,
.comprehensive-stepper li.active:nth-child(3) span.bar-circle {
  animation-name: fillColorAnimation;
  // animation-duration: .5s;
  animation-fill-mode: forwards;
  // animation-delay: 2s;
}

.comprehensive-stepper li.active-tab:nth-child(4) span.bar-circle,
.comprehensive-stepper li.active:nth-child(4) span.bar-circle {
  animation-name: fillColorAnimation;
  // animation-duration: .5s;
  animation-fill-mode: forwards;
  // animation-delay: 3s;
}

@media screen and (min-width: 992px) {

  //For Large Display
  .comprehensive-stepper li span.disable-bar,
  .comprehensive-stepper li span.active {
    left: 156px;
    width: 270px;
  }
}

.submit-button {
  color: #fff;
  background: #ed5c4c;
  opacity: 1;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  width: 100%;
}

.submit-button:disabled {
  background: #ccc;
}

.phone-number {
  display: block;
  padding: 10px 10px 10px 55px;
  font-size: 16px;
  font-weight: 400;
}

.country-code {
  position: absolute;
  display: block;
  left: 10px;
  top: 10px;
  z-index: 2;
  font-size: 16px;
  font-weight: 400;
  color: rgb(108, 134, 153);
}

.infoDescription {
  display: none;
}

.tooltip:hover .infoDescription {
  display: block;
  position: relative;
  top: 10px;
}

//quote-summary CSS
.editBtn {
  background: #DFE7ED 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  width: 85px;
  height: 38px;
}

.proceed-btn {
  background: #98BCD5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 22px;
  // font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0px;
  color: #222B4A;
}

.proceed-btn:hover {
  background-color: #222B4A;
  color: white;
}

.quote-summary .card-title {
  font-size: 36px;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  line-height: 24px;
  // font: normal normal 900 36px/24px Roboto;
  letter-spacing: 0.34px;
  color: #DA512D;
}

.green-color {
  color: #16A21A;
  font-weight: bold !important;
}

.red-color {
  color: #D01814;
  font-weight: bold !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ar .quote-summary-left-part .parameter-name {
  margin-right: 3rem;
}

.quote-summary-left-part p {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  line-height: 24px;
  // font: normal normal bold 16px/24px Roboto;
  letter-spacing: 0.19px;
  color: #DA512D;
  text-transform: uppercase;
  margin-left: 3rem;
}

.text-muted {
  color: white !important;
  opacity: 0.5 !important;
}

.comprehensive-params .quote-summary-left-part p,
.addOns .quote-summary-left-part p {
  color: #222B4A;
  // text-transform: capitalize;
  text-transform: none;
  font-weight: 500;
}

.comprehensive-params .sublimit.default-sublimit {
  background: #222B4A;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #222B4A;
}

.comprehensive-params .sublimit.default-sublimit button {
  color: white;
  text-align: center;
  padding: 0;
}

.btn:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.comprehensive-params .sublimit button {
  color: #222B4A;
  text-align: center;
  padding: 0;
}

.comprehensive-params .active button {
  color: white;
}

.comprehensive-params .sublimit {
  background: white;
  height: 24px;
  border-radius: 12px;
  border: 1px solid #222B4A;
}

.quote-summary-checkbox .mat-checkbox-layout {
  position: relative;
  left: -24px;
  margin-bottom: 0px;
  font-weight: 700;
}

.quote-summary-left-part {
  max-width: 300px;
}

.quote-summary-icon p {
  line-height: 0px;
}

.quote-summary-icon ul li,
.quote-summary-icon .list-group button,
.list-group button,
.btn.list-group-item {
  border: 1px solid #222B4A;
  border-radius: 12px !important;
  height: 25%;
  margin-right: 4px;
  padding: 0px;
  width: 20%;
}

.blue,
.active {
  background-color: #222B4A !important;
  color: white !important;
  border-color: #222b4a;
}

.orange-text {
  color: #ed5c4c;
}

.blue-text {
  color: #222c4a;
}

.index-card {
  max-width: 28%;
}

.options-card {
  max-width: 18%;
}

.add-ons {
  padding-top: 8px;
}

.add-ons div {
  border-radius: 12px;
  opacity: 1;
}

.comprehensive-addon p,
.add-ons>div {
  height: 60px;
  padding-top: 20px;
}

.outline {
  margin-top: 6px;
  border: 1px solid #222B4A;
}

.agency .blue,
.agency .outline {
  height: 21px;
  margin-left: 50px;
  margin-right: 50px;
}

.add-ons>div {
  padding-bottom: 20px;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  background-color: #222c4a !important;
  border-color: #222c4a !important;
}

.qrCss {
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(5px 5px 5px #222222);
  opacity: .5;
}

.insuranceDetails td,
.insuranceDetails th,
.quote-table td,
.quote-table th {
  border: none;
}

.trBackground1 {
  background: #DFE7ED60 0% 0% no-repeat padding-box;
}

.trBackground2 {
  background: #DFE7ED30 0% 0% no-repeat padding-box;
}

.subBlue {
  color: #222B4A;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 20px;
  // font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0.19px;
}

@keyframes fillColorAnimation {
  100% {
    background-color: #222B4a;
    border-color: #222B4a;
  }
}
